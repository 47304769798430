// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-dermatologia-konsultacja-dermatologiczna-tsx": () => import("./../../../src/pages/oferta/dermatologia/konsultacja-dermatologiczna.tsx" /* webpackChunkName: "component---src-pages-oferta-dermatologia-konsultacja-dermatologiczna-tsx" */),
  "component---src-pages-oferta-dietetyka-konsultacja-dietetyczna-tsx": () => import("./../../../src/pages/oferta/dietetyka/konsultacja-dietetyczna.tsx" /* webpackChunkName: "component---src-pages-oferta-dietetyka-konsultacja-dietetyczna-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-aplikacja-kolagenu-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/aplikacja-kolagenu.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-aplikacja-kolagenu-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-botoks-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/botoks.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-botoks-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-karboksyterapia-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/karboksyterapia.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-karboksyterapia-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-mezoterapia-iglowa-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/mezoterapia-iglowa.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-mezoterapia-iglowa-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-peelengi-chemiczne-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/peelengi-chemiczne.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-peelengi-chemiczne-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-prx-t-33-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/prx-t33.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-prx-t-33-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-wypelniacz-algeness-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/wypelniacz-algeness.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-wypelniacz-algeness-tsx" */),
  "component---src-pages-oferta-medycyna-estetyczna-wypelniacze-tsx": () => import("./../../../src/pages/oferta/medycyna-estetyczna/wypelniacze.tsx" /* webpackChunkName: "component---src-pages-oferta-medycyna-estetyczna-wypelniacze-tsx" */),
  "component---src-pages-oferta-stomatologia-chirurgia-jamy-ustnej-tsx": () => import("./../../../src/pages/oferta/stomatologia/chirurgia-jamy-ustnej.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-chirurgia-jamy-ustnej-tsx" */),
  "component---src-pages-oferta-stomatologia-implantologia-tsx": () => import("./../../../src/pages/oferta/stomatologia/implantologia.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-implantologia-tsx" */),
  "component---src-pages-oferta-stomatologia-korony-protetyczne-tsx": () => import("./../../../src/pages/oferta/stomatologia/korony-protetyczne.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-korony-protetyczne-tsx" */),
  "component---src-pages-oferta-stomatologia-leczenie-kanalowe-tsx": () => import("./../../../src/pages/oferta/stomatologia/leczenie-kanalowe.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-leczenie-kanalowe-tsx" */),
  "component---src-pages-oferta-stomatologia-licowki-porcelanowe-tsx": () => import("./../../../src/pages/oferta/stomatologia/licowki-porcelanowe.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-licowki-porcelanowe-tsx" */),
  "component---src-pages-oferta-stomatologia-mosty-protetyczne-tsx": () => import("./../../../src/pages/oferta/stomatologia/mosty-protetyczne.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-mosty-protetyczne-tsx" */),
  "component---src-pages-oferta-stomatologia-ortodoncja-tsx": () => import("./../../../src/pages/oferta/stomatologia/ortodoncja.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-ortodoncja-tsx" */),
  "component---src-pages-oferta-stomatologia-profilaktyka-stomatologiczna-tsx": () => import("./../../../src/pages/oferta/stomatologia/profilaktyka-stomatologiczna.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-profilaktyka-stomatologiczna-tsx" */),
  "component---src-pages-oferta-stomatologia-protezy-ruchome-tsx": () => import("./../../../src/pages/oferta/stomatologia/protezy-ruchome.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-protezy-ruchome-tsx" */),
  "component---src-pages-oferta-stomatologia-stomatologia-dziecieca-tsx": () => import("./../../../src/pages/oferta/stomatologia/stomatologia-dziecieca.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-stomatologia-dziecieca-tsx" */),
  "component---src-pages-oferta-stomatologia-stomatologia-zachowawcza-tsx": () => import("./../../../src/pages/oferta/stomatologia/stomatologia-zachowawcza.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-stomatologia-zachowawcza-tsx" */),
  "component---src-pages-oferta-stomatologia-wybielanie-zebow-tsx": () => import("./../../../src/pages/oferta/stomatologia/wybielanie-zebow.tsx" /* webpackChunkName: "component---src-pages-oferta-stomatologia-wybielanie-zebow-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog/category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

