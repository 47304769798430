/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location, prevLocation }) => {
  const body = document.querySelector('body')
  // body.style.opacity = 0;

  function tryExecuteMainScript() {
    setTimeout(function() {
      if(window.MainScript) {
        window.MainScript()
        // setTimeout(function() {
        //   body.style.opacity = 1;
        // }, 75)
      } else {
        tryExecuteMainScript()
      }
    }, 10)
  }

  tryExecuteMainScript()
};

